
import { defineComponent } from 'vue';
import { WorkController, ProjectController, TimeEntryController, SessionController, ClientController, DepartmentController } from '@/controller';
import { List, ListItem, Searchbar, UiTabs, UiTab, IconButton, Button, Switch } from '@/ui';
import { User, Project, Employee, Work, Company, Department } from '@/model';
import store from '@/store/index';

export default defineComponent({
    name: "AddTimeEntry",
    components: { List, ListItem, Searchbar, UiTabs, UiTab, IconButton, Button, Switch},
    created(){
        this.getSessionUser()
        this.getDepartments()
    },
    data(){
        return{
            store,
            projects: [] as Array<Project>,
            clients: [] as Array<any>,
            works: [] as Array<Work>,
            departments: [] as Array<Department>,
            form:{
                projectId: 0,
                customerId: 0,
                workId: 0,
                workstationId: 0,
                employeeId: 0,
            },
            searchInput:'',
            activeTab: 0,
            sessionUser: {} as User,
            sessionEmployee: {} as Employee,
            sessionCompany: {} as Company,
            showAllWorks: false,
            showAllProjects: false
        }
    },
    computed:{
        filteredProjectList(): Project[] {
            if(this.projects){
                let projectList = [] as Project[]

                if (!this.showAllProjects && this.searchInput == "") {
                    //projectList.log('filteredProjectList', projectList)
                    //projectList = this.projects.filter(project => work.getDepartments().some(dep => this.employeeDepartments.find(empDep => empDep.uid == dep.uid)))
                    projectList = this.defaultProject ? [...[this.defaultProject], ...this.employeeProjects] : [...this.employeeProjects]
                    //console.log('filteredProjectList', projectList)
                } else {
                    projectList = [...this.projects]
                } 

                if(this.searchInput != ""){
                    projectList = projectList.filter((item:any) => {
                        if (
                            item?.title.toLowerCase().includes(this.searchInput.toLowerCase()) || 
                            item?.numberExternal?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                            item?.numberInternal?.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                            item?.numberSerial?.toLowerCase().includes(this.searchInput.toLowerCase()) ||                           
                            item?.customer?.custName.toLowerCase().includes(this.searchInput.toLowerCase())
                        ) {
                            return item
                        }
                    })
                }
                return projectList
            }
            return []
        },
        filteredWorkList(): Work[] {
            if (this.works) {
                
                let workList : any = this.works

                if (!this.showAllWorks) {
                    workList = this.works.filter(work => work.getDepartments().some(dep => this.employeeDepartments.find(empDep => empDep.uid == dep.uid)))
                    console.log('filteredWorkList', workList)
                }

                if(this.searchInput != ""){
                    workList = workList.filter((item:any)=>{
                        if(
                            item?.title?.toLowerCase().includes(this.searchInput.toLowerCase())) 
                        {
                            return item
                        }
                    })
                }
                return workList
            }
            return []
        },
        defaultProject(): Project {
            return this.projects.find((project: Project) => project.getUid() == this.sessionCompany?.settings?.defaultProject) as Project
        }, 
        employeeProjects(): Array<Project> {
            return this.projects.filter((project: Project) => this.sessionEmployee.activeProjects.find(active => active.uid == project.uid) != null)
        },
        employeeDepartments(): Array<Department> {
            return this.departments.filter((dep: Department) => this.sessionEmployee.departments.find(empDep => empDep.uid == dep.uid) != null)
        }
    },
    methods: {
        async getSessionUser(){
            this.sessionUser = SessionController.sessionUser
            this.sessionEmployee = SessionController.sessionEmployee
            this.sessionCompany = SessionController.sessionCompany
            this.form.employeeId = this.sessionEmployee ? this.sessionEmployee.getUid() : 0
            
            this.handleGetProjects()
            this.handleGetClients()
            this.getWorks()
        },
        handleCancel(){
            this.$router.go(-1)
        },
        handleOnChangeSearchInput(input : string){
            this.searchInput = input
        },
        handleSelectProject(item:any){
            this.form.projectId = item.uid
            this.form.customerId = item.customer.uid
            this.handleSwitchTab(+1)
        },
        handleSelectWork(item:any){
            this.form.workId = item.uid
            this.handleSwitchTab(+1)
        },
        handleSwitchTab(number:any){
            this.activeTab += number
            this.searchInput = ""
            if(this.activeTab==2){
                this.handleCreateTimeEntry()
            }
        },
        handleSetDefaultProject() {
            if (this.sessionCompany?.settings?.defaultProject != 0) {
                this.form.projectId = this.sessionCompany?.settings?.defaultProject as number
                this.handleSwitchTab(+1)         
            }
        },
        handleToggleWorks() {
            this.showAllWorks = !this.showAllWorks
        },
        handleToggleProjects() {
            this.showAllProjects = !this.showAllProjects
        },        
        async handleGetProjects(){
            this.projects = (await ProjectController.fetchProjects()).items;
        },
        async handleGetClients(){
            this.clients = await ClientController.fetchClients()
        },
        async getWorks(){
            const res = await WorkController.fetchWorks()
            this.works = res.items ? res.items : []
        },  
        async getDepartments(){
            const res = await DepartmentController.fetchDepartments()
            this.departments = (res.items ? res.items : [])
        },        
        async handleCreateTimeEntry(){
            await TimeEntryController.createTimeEntry(this.form.employeeId, this.form.workId, this.form.projectId, this.form.customerId, this.form.workstationId)
            this.$router.go(-1) 
        },
    },
})
